<template>
  <b-container>
    <b-modal
      id="modal-center-62"
      centered
      title="Select Action"
    >
      <b-button
        size="sm"
        variant="primary"
        @click="goToAppointment()"
      >
        Edit Form</b-button>
      <!-- <b-button @click="goToPatientDetails()" variant="primary">
          View patient details</b-button
        > -->
    </b-modal>

    <b-row
      style="width:100%"
      class="mx-auto mt-2"
    >
      <list-component
        style="width:100%"
        :doctype="doctype"
        :fields="fields"
        @selected="rowSelected"
      />
    </b-row>
  </b-container>
</template>

<script>

import ListComponent from '@/views/components/doctype-list/ListComponent.vue'

export default {
  name: 'FormsList',
  components: { ListComponent },
  data() {
    return {
      doctype: 'Form Design',
      title: 'Forms List',
      subTitle: '',
      fields: ['name', 'department', 'workflow_state', 'creation'],
      id: '',
    }
  },mounted(){
    this.setTitle("Form List")
  },
  methods: {
    goToAppointment() {
      const { id } = this
      this.$router.push({ name: 'edit-form', params: { id } })
    },
    goToPatientDetails() {
      const { id } = this
      this.$router.push({ name: 'patient-view-actions', params: { id } })
    },

    rowSelected(item) {
      if (item && item.length) {
        this.id = item[0].name
        this.$bvModal.show('modal-center-62')
      }
    },
  },
}
</script>

  <style></style>
